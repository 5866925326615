import React from 'react'

function CompareChannels() {
    return (
        <div>
            Compare
        </div>
    )
}

export default CompareChannels
