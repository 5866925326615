import React from 'react'

function SimilarChannels() {
    return (
        <div>
            Similar Channels
        </div>
    )
}

export default SimilarChannels
