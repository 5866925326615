// assets
import { IconBrandChrome, IconHelp, IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome: IconBrandChrome,
    IconHelp: IconHelp,
    IconSitemap: IconSitemap
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'similar_channel',
            title: 'Similar Channel',
            type: 'item',
            url: '/similar_channel',
            icon: icons['IconBrandChrome'],
            breadcrumbs: false
        },
        {
            id: 'compare_channel',
            title: 'Similar Channel',
            type: 'item',
            url: '/compare_channel',
            icon: icons['IconBrandChrome'],
            breadcrumbs: false
        },
    ]
};
